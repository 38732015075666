import { Component } from '@angular/core';
import { ScreenService } from 'grow-angular/services';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  isMobile$: Observable<boolean> = this.screenService.isMobile$;
  year = new Date().getFullYear();
  constructor(private screenService: ScreenService) {}
}
