import { Directive, HostListener, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[HintForSelects]',
})
export class HintForSelectsDirective {
  @Input('HintForSelects') source: { isMulty: boolean; hintName: string };
  constructor(private translate?: TranslateService) {}

  @HostListener('onSelectionChanged', ['$event']) onSelectionChanged(event) {
    let hintText = '';
    if (this.checkValuePresent(event)) {
      hintText = this.source.isMulty
        ? this.getHintTextForSelectBox(event)
        : event.component.option().selectedItem[this.source.hintName];
    }
    hintText = this.translate.instant(hintText);
    event.component.option('hint', hintText);
  }

  checkValuePresent(event) {
    return (
      event &&
      ((event.component.option().selectedItems && event.component.option().selectedItems.length === 1) ||
        event.component.option().selectedItem)
    );
  }

  getHintTextForSelectBox(event) {
    return event.component.option().selectedItems[0][this.source.hintName];
  }
}
