import { LauncherAlignmentType, LauncherTypesType } from '../constants';

export class LauncherModel {
  constructor(
    public id: string,
    public uuid: string,
    public key: string,
    public type: LauncherTypesType,
    public label: string,
    public alignment?: LauncherAlignmentType,
    public baseUrl?: string,
    public path?: string,
    public image?: string,
    public cssClasses?: string,
    public previewText?: string,
    public text?: string,
    public order?: number,
    public items?: LauncherModel[],
    public level?: number,
    public parent?: LauncherModel,
    public additionalConfiguration?: string
  ) {}
}
