import { Injectable } from '@angular/core';
import { fromEvent } from 'rxjs';
import { log } from '../../../debug';
import { OAuthService } from 'angular-oauth2-oidc';
import Guid from 'devextreme/core/guid';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

interface IncomingMessageData {
  'grow-frame'?: {
    secret: string;
    token?: 'subscribe';
  };
}

interface OutgoingMessageData {
  'grow-frame': {
    token?: string;
  };
}

interface TokenTarget {
  subscribedSince: string;
  type: 'token';
  target: MessageEventSource;
  targetOrigin: string;
}

export class FrameLease {
  public readonly frame: string;
  public constructor(baseName: string, private readonly onDispose: (lease: FrameLease) => void) {
    this.frame = `${baseName}::${new Guid().toString()}`;
  }
  public dispose() {
    this.onDispose(this);
  }
}

export class CommuncationTarget {
  public constructor(
    readonly secret: string,
    readonly baseUrl: string,
    readonly path: string,
    readonly url: SafeResourceUrl
  ) {}
}

interface RemoteTarget {
  secret: string;
  frame: string;
  tokenTarget?: TokenTarget;
}
@Injectable({
  providedIn: 'root',
})
export class GrowTokenService {
  private $msg;
  private $authEvent;
  private targets: Map<string, RemoteTarget> = new Map<string, RemoteTarget>();

  public lease(settings: { frame: string }) {
    return new FrameLease(settings.frame, (lease) => {
      this.targets.delete(lease.frame);
    });
  }
  public enableCommunication({ lease, baseUrl, path }: { lease: FrameLease; baseUrl: string; path: string }) {
    const remoteTarget = {
      frame: lease.frame,
      secret: new Guid().toString(),
    };
    this.targets.set(lease.frame, remoteTarget);

    const base = new URL(baseUrl);
    const rawUrl = new URL(path.replace(/^\/+/, ''), base);
    rawUrl.searchParams.append('grow-secret', remoteTarget.secret);

    const url = this.sanitizer.bypassSecurityTrustResourceUrl(rawUrl.href);

    return new CommuncationTarget(remoteTarget.secret, baseUrl, path, url);
  }

  public debug() {
    this.notifyTokenTargets();
  }
  private debugAccessToken() {
    const token = this.oauthService.getAccessToken();
    if (!token) {
      log(`No token yet`);
      return;
    }
    const [header, data, sig] = token.split('.');
    const jsonData = JSON.parse(atob(data));
    const exp = jsonData['exp'];
    if (typeof exp === 'number') {
      const d = new Date(exp * 1000);
      log(`Expires: ${d.toISOString()}`);
    }
    log(`header: %o`, jsonData);
  }

  private notifyTarget(tgt: TokenTarget) {
    const out: OutgoingMessageData = { 'grow-frame': { token: this.oauthService.getAccessToken() } };
    tgt.target.postMessage(out, { targetOrigin: tgt.targetOrigin });
  }
  private notifyTokenTargets() {
    for (const [name, tgt] of this.targets) {
      if (tgt.tokenTarget) {
        this.notifyTarget(tgt.tokenTarget);
      }
    }
  }

  constructor(private readonly sanitizer: DomSanitizer, private readonly oauthService: OAuthService) {
    //this.debugAccessToken();
    this.$authEvent = oauthService.events.subscribe((x) => {
      if (x.type === 'token_received' || x.type == 'silently_refreshed' || x.type == 'token_refreshed') {
        //this.debugAccessToken();
        this.notifyTokenTargets();
      }
    });
    this.$msg = fromEvent(window, 'message').subscribe((e: MessageEvent) => {
      log('grow token service %o', e);
      const incoming: IncomingMessageData = e.data;
      const d = incoming['grow-frame'];
      if (!d) {
        return;
      }
      if (typeof d.secret !== 'string') {
        log(`Ignoring postMessage, 'secret' is missing`);
        return;
      }
      const f = [...this.targets.entries()].filter((x) => x[1].secret === d.secret);
      if (!f.length || f.length !== 1) {
        log(`Ignoring postMessage, 'secret' is invalid`);
        return;
      }
      if (d.token) {
        if (d.token === 'subscribe') {
          const tgt: TokenTarget = {
            type: 'token',
            target: e.source,
            targetOrigin: e.origin,
            subscribedSince: new Date().toISOString(),
          };
          this.notifyTarget(tgt);
          f[0][1].tokenTarget = tgt;
        }
      }
    });
  }
  ngOnDestroy(): void {
    this.$msg.unsubscribe();
    this.$msg = undefined;
    this.$authEvent.unsubscribe();
    this.$authEvent = undefined;
  }
}
