import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';

export { DateTimeService, DateTimeConfig, DATETIME_SERVICE_CONFIG_TOKEN };

@Injectable({
  providedIn: 'root',
})
class DateTimeService {
  private userDateFormat: string;
  private realmDateFormat: string;
  private timeFormat: string = 'HH:mm';

  constructor(@Optional() @Inject(DATETIME_SERVICE_CONFIG_TOKEN) private config?: DateTimeConfig) {}

  setUserDateFormat(format: string) {
    this.userDateFormat = format;
  }

  setRealmDateFormat(format: string) {
    this.realmDateFormat = format;
  }

  getDateFormat(): string {
    return this.userDateFormat || this.realmDateFormat || this.getDefaultDateFormat();
  }

  getTimeFormat(): string {
    return this.timeFormat;
  }

  getDateTimeFormat() {
    return `${this.getDateFormat()} ${this.getTimeFormat()}`;
  }

  getDefaultDateFormat() {
    return this.config?.defaultDateFormat || 'dd.MM.yyyy';
  }
}

type DateTimeConfig = { defaultDateFormat?: string };

const DATETIME_SERVICE_CONFIG_TOKEN = new InjectionToken<DateTimeConfig>('dateTimeService.config');
