<div class="main-side-nav dx-swatch-additional-colors dx-theme-border-color">
  <div class="main-menu-btn dx-theme-background-color">
    <dx-toolbar [items]="toolbarContent" *ngIf="!(isMobile$ | async)">
      <div *dxTemplate="let menu of 'burgerMenu'">
        <dx-button [hint]="'BURGER_MENU' | translate" (click)="menuToast()">
          <i-feather [name]="icons.Menu" class="dx-button-has-icon btn-icon"></i-feather>
        </dx-button>
      </div>
    </dx-toolbar>
  </div>
  <dx-tree-view
    *ngIf="menuItems?.length"
    #tree
    class="navigation-item"
    [items]="menuItems"
    (onItemClick)="menuItemClick($event)"
    itemTemplate="itemTemplate"
    selectionMode="single"
    (onContentReady)="updateOpenedSubMenuStyle()"
    keyExpr="id"
  >
    <div [class]="menuItem.cssClasses" *dxTemplate="let menuItem of 'itemTemplate'">
      <div [ngClass]="{ 'sub-menu-item': menuItem.level > 1, 'external-item': menuItem.type === launcherTypes.WINDOW }" class="menu-item dx-theme-text-color">
        <span class="menu-icon">
          <ng-container *ngIf="menuItem.level === 1">
            <img *ngIf="!!menuItem.image" [src]="menuItem.image" class="dx-button-has-icon btn-icon"/>
            <span *ngIf="!menuItem.image" class="dx-button-has-icon btn-icon">{{menuItem.previewText}}</span>
          </ng-container>
        </span>
        <h4>{{ menuItem.text | translate }}</h4>
        <i-feather *ngIf="menuItem.type === launcherTypes.WINDOW" class="external-link-icon" [name]="icons.ExternalLink"></i-feather>
      </div>
    </div>
  </dx-tree-view>
</div>
