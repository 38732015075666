import { ToolbarMenuComponent } from 'src/app/shared/components';
import { cloneDeep } from 'lodash';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-secondary-menu',
  templateUrl: './secondary-menu.component.html',
  styleUrls: ['./secondary-menu.component.scss'],
})
export class SecondaryMenuComponent extends ToolbarMenuComponent implements OnInit {
  ngOnInit() {
    this.sub$.push(
      this.context$.subscribe((data) => {
        this.context = data;
        this.menuItems = cloneDeep(data.menuItems.secondary);
        this.markActiveItems(this.menuItems);
        this.currentItem = this.getActivatedLink(this.menuItems);
      })
    );
    this.sub$.push(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.updateMenuState();
        }
      })
    );
  }

  updateMenuState() {
    this.markActiveItems(this.menuItems);
    this.previousItem = this.currentItem;
    this.currentItem = this.getActivatedLink(this.menuItems);
  }

  handleFolderNavigation(item) {
    this.openSubMenu(item);
  }
}
