<div class="secondary-menu">
  <div class="dx-theme-border-color">
    <dx-toolbar>
      <dxi-item
        *ngFor="let menuItem of menuItems; let i = index"
        cssClass="{{ addClass(menuItem) }}"
        [options]="{
          path: menuItem.path,
          isActive: menuItem.isActive
        }"
        locateInMenu="{{ i > 3 ? 'always' : 'never' }}"
      >
        <div *dxTemplate>
          <div
            [class]="menuItem.cssClasses"
            [ngClass]="{ 'sub-menu-item': menuItem.level > 1, 'external-item': menuItem.type === launcherTypes.WINDOW }"
            class="menu-item"
            (click)="handleNavigation(menuItem)"
          >
            <h4>{{ menuItem.text | translate }}</h4>
            <i-feather
              *ngIf="menuItem.type === launcherTypes.WINDOW"
              class="external-link-icon"
              [name]="icons.ExternalLink"
            ></i-feather>
          </div>
        </div>
      </dxi-item>
    </dx-toolbar>
  </div>
  <dx-action-sheet
    #subNavigationList
    [items]="subMenuItems"
    itemTemplate="template"
    [visible]="false"
    [showTitle]="false"
    [showCancelButton]="true"
  >
    <div *dxTemplate="let item of 'template'">
      <div [ngClass]="addClass(item)" class="dx-theme-border-color dx-button">
        <div class="menu-item sub-menu-item" (click)="handleNavigation(item)">
          <span>{{ item.text | translate }} </span>
          <i-feather
            *ngIf="item.type === launcherTypes.WINDOW"
            class="external-link-icon"
            [name]="icons.ExternalLink"
          ></i-feather>
        </div>
      </div>
    </div>
  </dx-action-sheet>
</div>
