<div class="user-avatar">
  <div class="form-avatar">
    <img class="dx-theme-border-color" alt="{{ username }} {{ 'picture_title' | translate }}" [src]="imageUrl" />
  </div>
  <dx-file-uploader
    class="user-avatar-upload dx-theme-background-color"
    accept="image/*"
    [uploadUrl]="uploadApiUrl"
    [uploadHeaders]="httpHeaders"
    [multiple]="false"
    labelText=""
    hoverStateEnabled="false"
    showFileList="false"
    allowCanceling="false"
    uploadMode="instantly"
    name="files"
    (onValueChanged)="selectPicture($event)"
    (onUploaded)="uploaded($event)"
  >
  </dx-file-uploader>
  <div class="picture-actions">
    <dx-button
      type="default"
      (onClick)="uploadPicture()"
      stylingMode="outlined"
      [text]="'upload_picture' | translate"
    ></dx-button>
    <dx-button
    *ngIf="showDeleteButton"
      type="danger"
      (onClick)="deleteImage()"
      stylingMode="outlined"
      [text]="'delete_profile_picture' | translate"
    ></dx-button>
  </div>
</div>
