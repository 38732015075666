import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, tap } from 'rxjs/operators';

const MOBILE_MAX_WIDTH = 960;
const TABLET_MAX_WIDTH = 1024;
const MOBILE_MAX_HEIGHT = 420;

@Injectable({
  providedIn: 'root',
})
export class ScreenService {
  public _resize$ = fromEvent(window, 'resize').pipe(
    debounceTime(50),
    map(() => {
      return { width: window.innerWidth, height: screen.availHeight };
    }),
    distinctUntilChanged(),
    startWith({ width: window.innerWidth, height: screen.availHeight }),
    tap((e) => {
      const mobile = e.width <= MOBILE_MAX_WIDTH;
      const landscape = e.height <= MOBILE_MAX_HEIGHT;
      const tablet = e.width <= TABLET_MAX_WIDTH && e.width > MOBILE_MAX_WIDTH;
      const dimensions = { width: e.width, height: e.height };
      this.isMobileSubject$.next(mobile);
      this.isTabletSubject$.next(tablet);
      this.isLandscapeSubject$.next(landscape);
      this.isDesktopSubject$.next(!mobile && !tablet);
      this.dimensionsSubject$.next(dimensions);
    })
  );

  public pageScroll$ = new Subject();

  private isMobileSubject$ = new BehaviorSubject(false);
  public isMobile$ = this.isMobileSubject$.asObservable();
  private isTabletSubject$ = new BehaviorSubject(false);
  public isTablet$ = this.isTabletSubject$.asObservable();
  private isLandscapeSubject$ = new BehaviorSubject(false);
  public isLandscape$ = this.isLandscapeSubject$.asObservable();
  private isDesktopSubject$ = new BehaviorSubject(false);
  public isDesktop$ = this.isDesktopSubject$.asObservable();
  private dimensionsSubject$ = new BehaviorSubject({ width: 0, height: 0 });
  public dimensions$ = this.dimensionsSubject$.asObservable();

  constructor() {}
}
