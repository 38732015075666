<dx-select-box
  [(value)]="value"
  [dataSource]="roles | async"
  displayExpr="name"
  valueExpr="id"
  [searchEnabled]="false"
  [showClearButton]="false"
  (valueChange)="modelChanged()"
  [disabled]="disabled"
  itemTemplate="template"
  fieldTemplate="fieldTemplate"
  [HintForSelects]="{ isMulty: false, hintName: 'name' }"
>
  <div *dxTemplate="let data of 'template'">
    <div [title]="data.name | translate">{{ data.name | translate }}</div>
  </div>
  <div *dxTemplate="let field of 'fieldTemplate'">
    <dx-text-box
      [value]="field?.name"
      [text]="field?.name | translate"
      [placeholder]="'select' | translate"
      [readOnly]="true"
    ></dx-text-box>
  </div>
  <dx-validator validationGroup="customerData">
    <dxi-validation-rule
      *ngIf="validate"
      type="required"
      [message]="'rolePermission is required'"
    ></dxi-validation-rule>
  </dx-validator>
</dx-select-box>
