import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  contentClass: string;
  showFooter: boolean;

  constructor() {
    this.setDefaultValues();
  }

  setDefaultValues() {
    this.contentClass = '';
    this.showFooter = true;
  }
}
