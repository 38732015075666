import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ContextsQuery } from 'src/app/members.api.g';
import { AppContext } from '../contracts';

type Context = ContextsQuery['context']['onlyWithLaunchers']['nodes'][0];

@Injectable({
  providedIn: 'root',
})
export class ContextStateService {
  private _contexts = new BehaviorSubject<Context[]>(null);
  private _currentContext = new BehaviorSubject<AppContext>(null);
  public contexts = this._contexts.asObservable();
  public currentContext = this._currentContext.asObservable();

  setCurrentContext(context: AppContext) {
    this._currentContext.next(context);
  }

  getContext(): Observable<AppContext> {
    return this.currentContext;
  }

  setContexts(contexts: Context[]) {
    this._contexts.next(contexts);
  }
}
