import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss'],
})
export class CountryComponent implements OnInit {
  @Input() validate: boolean = false;
  @Input() value: any;
  @Output() valueChange = new EventEmitter<any>();
  @Output() countryChanged = new EventEmitter<any>();
  countries;
  disabled = false;
  constructor() {}
  @Input() set isDisabled(value: boolean) {
    this.disabled = value;
  }

  ngOnInit() {
    this.countries = ['Österreich', 'Ukraine'];
  }

  changedValue(e) {
    this.countryChanged.emit(e.value);
  }
  modelChanged(e) {
    this.valueChange.emit(e.selectedItem);
  }
}
