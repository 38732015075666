import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { cloneDeep } from 'lodash';
import { LauncherModel } from '../../shared/models';
import { ToolbarMenuComponent } from 'src/app/shared/components';

@Component({
  selector: 'app-mobile-navigation',
  templateUrl: './mobile-navigation.component.html',
  styleUrls: ['./mobile-navigation.component.scss'],
})
export class MobileNavigationComponent extends ToolbarMenuComponent implements OnInit, OnDestroy {
  ngOnInit() {
    this.sub$.push(
      this.context$.subscribe((data) => {
        this.context = data;
        this.menuItems = cloneDeep(this.sortMenuItems(data.menuItems.primary, data.menuItems.secondary));
        this.markActiveItems(this.menuItems);
        this.markLastSecondary(this.menuItems);
        this.currentItem = this.getActivatedLink(this.menuItems);
      })
    );
    this.sub$.push(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.updateMenuState();
        }
      })
    );
  }

  updateMenuState() {
    this.markActiveItems(this.menuItems);
    this.previousItem = this.currentItem;
    this.currentItem = this.getActivatedLink(this.menuItems);
    if (this.currentItem?.parent && !this.subNavigationList.visible) {
      this.openSubMenu(this.currentItem.parent);
    } else if (this.currentItem?.items?.length) {
      this.openSubMenu(this.currentItem);
    }
  }

  markLastSecondary(items) {
    items.forEach((item, index) => {
      if (item.alignment === this.alignmentTypes.Secondary) {
        const nextItem = items[index + 1];
        item['isLastSecondary'] = !nextItem || nextItem.alignment === this.alignmentTypes.Primary;
      }
    });
  }

  handleFolderWithoutFrameEntries(item) {
    this.openSubMenu(item);
  }

  sortMenuItems(primaryItems: LauncherModel[], secondaryItems: LauncherModel[]) {
    return [...primaryItems.slice(0, 4), ...secondaryItems, ...primaryItems.slice(4)];
  }
}
