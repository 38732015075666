import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloClientOptions, InMemoryCache, ApolloLink } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { setContext } from '@apollo/client/link/context';
import { apiUrl } from './config';
import { HttpClientModule } from '@angular/common/http';

export function createApollo(this: void, httpLink: HttpLink, oauth: OAuthService): ApolloClientOptions<any> {
  const basic = setContext((operation, context) => ({
    headers: {
      Accept: 'charset=utf-8',
    },
  }));

  const auth = setContext(async (_, { headers }) => {
    // Grab token if there is one in storage or hasn't expired
    let token = oauth.getAccessToken();

    if (!token) {
      // An observable to fetch a new token
      // Converted .toPromise()
      await oauth.refreshToken();

      // Set new token to the response (adal puts the new token in storage when fetched)
      token = oauth.getAccessToken();
    }
    // Return the headers as usual
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  });
  const link = ApolloLink.from([basic, auth, httpLink.create({ uri: apiUrl })]);
  return {
    link,
    cache: new InMemoryCache(),
  };
}

@NgModule({
  imports: [ApolloModule, HttpClientModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, OAuthService],
    },
  ],
})
export class GraphQLModule {}
