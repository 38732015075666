import { Directive, ViewChild } from '@angular/core';
import { DxActionSheetComponent, DxToolbarComponent } from 'devextreme-angular';
import { AppShellLauncherAlignment } from 'src/app/members.api.g';
import { BaseMenuComponent } from './base-menu.component';

@Directive()
export class ToolbarMenuComponent extends BaseMenuComponent {
  @ViewChild(DxToolbarComponent, { static: false }) toolBar: DxToolbarComponent;
  @ViewChild('subNavigationList', { static: false }) subNavigationList: DxActionSheetComponent;
  alignmentTypes = AppShellLauncherAlignment;
  subMenuItems: any[];
  currentItem;
  previousItem;

  //need to investigate why item click does not close mobile and secondary menu dropdown
  handleNavigation(item) {
    setTimeout(() => super.handleNavigation(item));
  }

  markActiveItems(items) {
    items.forEach((item, index) => {
      /*item['onClick'] = () => {
        if (item.items && item.items.length) {
          this.openSubMenu(item);
        }
        if (item.path) {
          this.router.navigate([item.path]);
        }
      };*/
      item['isActive'] = this.checkIsActiveItem(item);
      return item;
    });
  }

  openSubMenu(item) {
    this.markActiveItems(item.items);
    this.subMenuItems = item.items;
    this.subNavigationList.visible = true;
  }

  checkIsActiveItem(item) {
    return this.checkPath(item) || !!item.items?.find((item) => this.checkPath(item));
  }

  addClass(item) {
    return item.isActive ? 'dx-theme-accent-as-text-color' : '';
  }
}
