import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ReceivePaginatedTableDataInterface } from 'src/app/shared/contracts';
import { log, error } from '../../../debug';
import { DEFAULT_PROFILE_PIC } from '../../../magic';
import { MeGQL, OwnProfileAccessEnum, User_By_IdGQL } from '../../members.api.g';
import { UserViewModel } from '../models';
import { ApiService } from './api.service';
import { ConfigService } from './config.service';
import { QueryGenerateService } from './query-generate.service';

@Injectable({
  providedIn: 'root',
})
export class UserService implements ReceivePaginatedTableDataInterface<UserViewModel> {
  constructor(
    private apiService: ApiService,
    private userById: User_By_IdGQL,
    private queryGenService: QueryGenerateService,
    private me: MeGQL,
    private oauthService: OAuthService,
    private configService: ConfigService
  ) {}

  getCurrentUser(): Observable<UserViewModel> {
    return this.me.watch().valueChanges.pipe(
      map((data) => {
        const retVal: UserViewModel = { imageBase64: DEFAULT_PROFILE_PIC };
        retVal.ownProfileAccess = data?.data?.ownProfileAccess;
        const user = data?.data?.members?.me;
        if (user) {
          retVal.uuid = user.uuid;
          retVal.firstName = user.firstName;
          retVal.lastName = user.lastName;
          retVal.name = user.name;
          retVal.contactEmail = user.contactEmail;
          retVal.dateFormat = user.dateFormat;
          retVal.theme = user.theme;
          retVal.phone = user.phone;
          retVal.location = user.location;
          retVal.imageBase64 = user.imageBase64 ? user.imageBase64 : DEFAULT_PROFILE_PIC;
        }
        const claims = this.oauthService.getIdentityClaims();
        const appLocales = this.configService.getAppLocales();
        const userLocale = claims['locale'] && appLocales.includes(claims['locale']) ? claims['locale'] : undefined;
        retVal.locale = userLocale;
        return retVal;
      }),
      catchError((e) => {
        error(e);
        throw e;
      })
    );
  }

  getOneUser(username: string): Observable<UserViewModel> {
    log(`Watching userById for ${username}`);
    const watch = this.userById.watch({
      id: { guess: username },
    });
    return watch.valueChanges.pipe(
      map((data) => {
        log(`Watching userById result: %o`, data);
        const retVal: UserViewModel = { imageBase64: DEFAULT_PROFILE_PIC };
        const user = data?.data?.members?.byId;
        if (user) {
          retVal.firstName = user.firstName;
          retVal.lastName = user.lastName;
          retVal.name = user.name;
          retVal.imageBase64 = user.imageBase64 ? user.imageBase64 : DEFAULT_PROFILE_PIC;
        }
        retVal.locale = 'en';
        return retVal;
      })
    );
  }

  getPaginatedItems(variables, objectType?): Observable<{ nodes: UserViewModel[]; totalCount: number }> {
    const query = this.queryGenService.createQuery(
      'query ($first: PaginationAmount, $order_by: UserSort, $where: UserFilter, $last: PaginationAmount)',
      'getUsers(first: $first, order_by: $order_by, where: $where, last: $last){nodes',
      objectType,
      '}totalCount'
    );
    return this.apiService.getOneOrMutationDataItem(query, variables).pipe(map((resp) => resp.getUsers));
  }

  getUserRoles(): Observable<Array<{ id: number; name: string }>> {
    let roles = [];
    return of(roles);
  }

  defaultValue() {
    return new UserViewModel(OwnProfileAccessEnum.Hidden, '', '', '', '', '', '', '');
  }
}
