<dx-select-box
  #typeSelect
  [(value)]="value"
  [placeholder]="'select' | translate"
  [dataSource]="types"
  [searchEnabled]="true"
  [attr.aria-label]="'switch_type' | translate"
  (valueChange)="modelChanged()"
>
</dx-select-box>
