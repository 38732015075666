import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { OAuthModule, OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';
import { DevExtremeModule } from 'devextreme-angular';
import { appInitializer } from 'grow-angular/app-initializer';
import { AcceptLanguageInterceptor, ACCEPT_LANGUAGE_CONFIG_TOKEN } from 'grow-angular/interceptors';
import {
  AuthService,
  AuthServiceConfig,
  AUTH_SERVICE_CONFIG_TOKEN,
  DateTimeConfig,
  DateTimeService,
  DATETIME_SERVICE_CONFIG_TOKEN,
  TranslationService,
  TRANSLATION_SERVICE_CONFIG_TOKEN,
} from 'grow-angular/services';
import { error } from '../debug';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { allowedLocales, apiUrl, appLocale, defaultDateFormat } from './config';
import { GraphQLModule } from './graphql.module';
import { CustomTranslateHttpLoader } from './shared/services';
import { SharedModule } from './shared/shared.module';

export function createTranslateLoader(http: HttpClient) {
  return new CustomTranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function storageFactory(): OAuthStorage {
  return localStorage;
}

//TODO: refactor config setup(?)
export function oAuthConfigFactory(): OAuthModuleConfig {
  const idmUrl = window.__CONFIG?.IDM_ISSUER || '';
  if (!idmUrl) {
    error('invalid config - IDM_ISSUER not set');
  }
  return {
    resourceServer: {
      allowedUrls: [idmUrl],
      sendAccessToken: true,
    },
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    GraphQLModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    DevExtremeModule,
    SharedModule,
    OAuthModule.forRoot(),
    NoopAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: OAuthModuleConfig,
      useFactory: oAuthConfigFactory,
    },
    { provide: OAuthStorage, useFactory: storageFactory },
    {
      provide: AUTH_SERVICE_CONFIG_TOKEN,
      useFactory: () => {
        const globalConfig = window.__CONFIG;
        const retVal: AuthServiceConfig = {
          clientId: globalConfig.IDM_CLIENTID,
          issuer: globalConfig.IDM_ISSUER,
          scope: globalConfig.IDM_SCOPE,
          //dummyClientSecret: globalConfig.IDM_CLIENT_SECRET,
          //requireHTTPS: true,
          //responseType: 'code',
        };
        return retVal;
      },
    },
    {
      provide: TRANSLATION_SERVICE_CONFIG_TOKEN,
      useFactory: () => {
        return {
          allowedLocales: allowedLocales,
          defaultLocale: appLocale,
        };
      },
    },
    {
      provide: ACCEPT_LANGUAGE_CONFIG_TOKEN,
      useFactory: () => {
        return { endpoints: [apiUrl] };
      },
    },
    {
      provide: DATETIME_SERVICE_CONFIG_TOKEN,
      useFactory: (): DateTimeConfig => {
        return { defaultDateFormat: defaultDateFormat };
      },
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: AcceptLanguageInterceptor,
      multi: true,
    },
    [
      {
        provide: APP_INITIALIZER,
        useFactory: appInitializer,
        deps: [AuthService, TranslationService, DateTimeService],
        multi: true,
      },
    ],
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
