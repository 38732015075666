import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from '../../../services';

@Component({
  selector: 'app-role-select',
  templateUrl: './role-select.component.html',
  styleUrls: ['./role-select.component.scss'],
})
export class RoleSelectComponent implements OnInit {
  @Input() validate: boolean = false;
  @Input() value: any;
  @Output() valueChange = new EventEmitter<any>();

  roles: Observable<any>;
  disabled = false;

  @Input() set isDisabled(value: boolean) {
    this.disabled = value;
  }

  constructor(private userService: UserService, private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.roles = this.userService.getUserRoles();
  }

  modelChanged() {
    this.valueChange.emit(this.value);
  }
}
