import { Injectable } from '@angular/core';
import themes from 'devextreme/ui/themes';
import { THEMES } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  currentTheme = localStorage.getItem('default_theme');
  setAppTheme(themeName) {
    const theme = THEMES.find((theme) => theme.name === themeName) || THEMES[0];
    theme.name === this.currentTheme ? themes.current(theme.baseTheme) : this.switchLocalTheme(theme.name);
  }
  getThemeName(): string {
    let result: string = '';
    if (this.currentTheme) {
      result = this.currentTheme;
    } else {
      result = 'light';
    }
    return result;
  }
  switchLocalTheme(themeName) {
    this.currentTheme = themeName;
    window.localStorage.setItem('default_theme', themeName);
  }
}
