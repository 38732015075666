import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslationService } from '../services';
import { formatURL } from 'grow-angular/helpers';

export { AcceptLanguageInterceptor, AcceptLanguageConfig, ACCEPT_LANGUAGE_CONFIG_TOKEN };

@Injectable()
class AcceptLanguageInterceptor implements HttpInterceptor {
  constructor(
    private translation: TranslationService,
    @Optional() @Inject(ACCEPT_LANGUAGE_CONFIG_TOKEN) private config?: AcceptLanguageConfig
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const endpoints = this.getEndpoints();
    let updatedRequest = req;
    let requestURL = formatURL(updatedRequest.url);
    let endpointURLs = endpoints.map((endpoint) => formatURL(endpoint));
    if (endpointURLs.includes(requestURL)) {
      updatedRequest = updatedRequest.clone({
        headers: req.headers.set('accept-language', this.translation.getCurrentLocale()),
      });
    }
    return next.handle(updatedRequest);
  }

  getEndpoints() {
    return this.config?.endpoints || [];
  }
}

type AcceptLanguageConfig = { endpoints: string[] };

const ACCEPT_LANGUAGE_CONFIG_TOKEN = new InjectionToken<AcceptLanguageConfig>('acceptLanguage.config');
