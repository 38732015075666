import { Injectable } from '@angular/core';
import { LauncherModel } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  activeModals: ModalConfig[] = [];

  open(launcher: LauncherModel) {
    if (this.activeModals.some((x) => x.id === launcher.id)) {
      return;
    }

    const additionalConfiguration = launcher.additionalConfiguration ? JSON.parse(launcher.additionalConfiguration): undefined;

    const options: ModalConfig = {
      id: launcher.id,
      title: launcher.text ?? '',
      showTitle: additionalConfiguration?.showTitle ?? true,
      overlay: additionalConfiguration?.overlay ?? true,
      draggable: additionalConfiguration?.draggable ?? false,
      hideOnOutsideClick: additionalConfiguration?.hideOnOutsideClick ?? true,
      showCloseButton: additionalConfiguration?.showCloseButton ?? true,
      width: additionalConfiguration?.width ?? '20rem',
      height: additionalConfiguration?.height ?? '20rem',
      resizeEnabled: additionalConfiguration?.resizeEnabled ?? false,
      at: additionalConfiguration?.at ?? 'center',
      my: additionalConfiguration?.my ?? 'center',
      of: additionalConfiguration?.of ?? 'window',
    };

    this.activeModals.push(options);
  }

  close(modalId: string) {
    const idx = this.activeModals.findIndex((x) => x.id === modalId);
    this.activeModals.splice(idx, 1);
  }
}

export interface ModalConfig {
  id: string;
  title: string;
  showTitle: boolean;
  overlay: boolean;
  draggable: boolean;
  hideOnOutsideClick: boolean;
  resizeEnabled: boolean;
  showCloseButton: boolean;
  width: string;
  height: string;
  at: string;
  my: string;
  of: string;
}
