import { AuthService, DateTimeService, TranslationService } from './services';

export function appInitializer(authService: AuthService, translation: TranslationService, dateTime: DateTimeService) {
  return () =>
    authService.runInitialLoginSequence().finally(() => {
      // datetime settings
      const userDateFormat = authService.claims ? authService.claims['date_format'] : null;
      const realmDateFormat = authService.claims ? authService.claims['default_date_format'] : null;
      dateTime.setUserDateFormat(userDateFormat);
      dateTime.setRealmDateFormat(realmDateFormat);
      // translation settings
      const userLocale = authService.claims ? authService.claims['locale'] : null;
      const defaultRealmLocale = authService.claims ? authService.claims['default_locale'] : null;
      translation.setUserLocale(userLocale);
      translation.setDefaultRealmLocale(defaultRealmLocale);
      return translation.loadMessages().toPromise();
    });
}
