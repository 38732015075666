<div class="m-navigation-bar dx-theme-border-color">
  <dx-toolbar>
    <dxi-item  *ngFor="let menuItem of menuItems; let i = index" cssClass="{{ addClass(menuItem) }}" [options]="{
        path: menuItem.path,
        isActive: menuItem.isActive
      }" locateInMenu="{{  menuItem.alignment === alignmentTypes.Primary ? 'always' : 'never' }}">
      <div [class]="menuItem.cssClasses"
        [ngClass]="{ 'secondary-menu-item': menuItem.alignment === alignmentTypes.Primary, 'last-secondary-menu-item': !menuItem.isLastSecondary }"
        class="menu-item dx-theme-border-color" (click)="handleNavigation(menuItem)">
        <span class="menu-icon" *ngIf="menuItem.alignment !== alignmentTypes.Secondary">
          <ng-container *ngIf="menuItem.level === 1">
            <img *ngIf="!!menuItem.image" [src]="menuItem.image" class="dx-button-has-icon btn-icon" />
            <span *ngIf="!menuItem.image" class="dx-button-has-icon btn-icon">{{ menuItem.previewText }}</span>
          </ng-container>
        </span>
        <img [src]='menuItem.image' alt="icons" width="25" height="25">
        <h4 *ngIf="menuItem.alignment === alignmentTypes.Secondary">{{ menuItem.text }}</h4>
      </div>
    </dxi-item>
  </dx-toolbar>
</div>
<dx-action-sheet #subNavigationList [items]="subMenuItems" itemTemplate="template" [visible]="false" [showTitle]="false"
  [showCancelButton]="true">
  <div *dxTemplate="let item of 'template'">
    <div [ngClass]="addClass(item)" class="dx-theme-border-color dx-button">
      <div class="menu-item sub-menu-item" (click)="handleNavigation(item)">
        <span>{{ item.text | translate }} </span>
      </div>
    </div>
  </div>
</dx-action-sheet>
