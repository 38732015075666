export enum Icons {
  Users = 'users',
  User = 'user',
  PlayCircle = 'play-circle',
  Info = 'info',
  ChevronDown = 'chevron-down',
  ChevronUp = 'chevron-up',
  ChevronLeft = 'chevron-left',
  ChevronRight = 'chevron-right',
  Edit2 = 'edit-2',
  Check = 'check',
  CheckSquare = 'check-square',
  Save = 'save',
  MoreHorizontal = 'more-horizontal',
  Filter = 'filter',
  Search = 'search',
  Synchronize = 'refresh-cw',
  Import = 'upload',
  Export = 'download',
  List = 'list',
  Grid = 'grid',
  Favorite = 'heart',
  Link2 = 'link-2',
  Eye = 'eye',
  Report = 'bar-chart-2',
  Trash2 = 'trash-2',
  Plus = 'plus',
  Minus = 'minus',
  Refresh = 'rotate-cw',
  NoDate = 'cloud-off',
  Menu = 'menu',
  Hierarchy = 'hierarchy',
  UserLock = 'user-lock',
  CustomGrid = 'custom-grid',
  Home = 'home',
  Film = 'film',
  Box = 'box',
  Columns = 'columns',
  ShoppingBag = 'shopping-bag',
  ExternalLink = 'external-link',
  Question = 'help-circle',
}
