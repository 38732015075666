import { Component } from '@angular/core';
import { AuthService } from 'grow-angular/services';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent {
  constructor(private authService: AuthService) {
    this.authService.initLogInFlow();
  }
}
