import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { custom } from 'devextreme/ui/dialog';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private translate: TranslateService) {}

  params = {
    message: '',
    closeOnOutsideClick: true,
    closeOnSwipe: true,
    position: { my: 'top', at: 'top', of: window },
  };

  successfulToast(text = 'Success request', param = '') {
    if (!!param) {
      const translatedParam = this.translate.instant(param);
      this.params.message = this.translate.instant(text, { value: translatedParam });
    } else {
      this.params.message = this.translate.instant(text);
    }
    notify(this.params, 'success', 5000);
  }

  errorToast(text = 'Bad request') {
    this.params.message = this.translate.instant(text);
    notify(this.params, 'error', 5000);
  }

  showAlert(question: string = 'Are you sure?', value: string = '') {
    return custom({
      messageHtml: question + value,
      buttons: [
        {
          text: this.translate.instant('confirm_yes_for_removing'),
          onClick: () => true,
        },
        {
          text: this.translate.instant('confirm_no_for_removing'),
          onClick: () => false,
        },
      ],
      showTitle: false,
    });
  }
}
