import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ToastService } from '../services';

@Directive({
  selector: '[isConfirm]',
})
export class IsConfirmDirective {
  @Input() popoverTitle: string;
  @Input() popoverQuestion: string;
  @Output() actionClicked = new EventEmitter<any>();

  constructor(public toastService: ToastService) {}

  @HostListener('click')
  openPopover(): void {
    this.toastService
      .showAlert(this.popoverQuestion, this.popoverTitle)
      .show()
      .then((result) => {
        if (result) {
          this.actionClicked.emit();
        }
      });
  }
}
