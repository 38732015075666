import { Injectable } from '@angular/core';
import { custom } from 'devextreme/ui/dialog';
import { Observable, of, from } from 'rxjs';
import { tap, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationService {
  private previousDialog = null;
  private dataUpdated: boolean = false;
  private extendedWarning: string = 'TRAILING_WARNING_MESSAGE_ON_UNSAVED_DATA';
  private warningTitle: string = 'DATA_WAS_CHANGED_BUT_NOT_SAVED_WARNING_TITLE';
  private yes: string = 'CUSTOM_DIALOG_YES';
  private no: string = 'CUSTOM_DIALOG_NO';

  constructor(private translate: TranslateService) {}

  requestConfirmation(warningMessage: string): Observable<boolean> {
    this.previousDialog?.hide();

    return this.dataUpdated
      ? this.translate.get(this.extendedWarning).pipe(
          switchMap((extendedWarning) =>
            this.translate.get([this.warningTitle, this.yes, this.no, warningMessage], { extendedWarning })
          ),
          switchMap((translatedWarning) => {
            this.previousDialog = this.createCustomConfirmDialog(translatedWarning, warningMessage);
            return from(this.previousDialog.show());
          }),
          switchMap((confirmed: boolean) => {
            if (confirmed) {
              this.dataUpdated = false;
              return of(true);
            }

            return of(false);
          })
        )
      : of(true).pipe(tap((confirmed) => (this.dataUpdated = !confirmed)));
  }

  get getDataUpdated() {
    return this.dataUpdated;
  }

  setDataUpdated() {
    this.dataUpdated = true;
  }

  unsetDataUpdated() {
    this.dataUpdated = false;
  }

  createCustomConfirmDialog(translatedWarning, warningMessage) {
    return custom({
      title: translatedWarning[this.warningTitle],
      message: translatedWarning[warningMessage],
      buttons: [
        {
          text: translatedWarning[this.yes],
          onClick: (event) => true,
        },
        {
          text: translatedWarning[this.no],
          onClick: (event) => false,
        },
      ],
    });
  }
}
