import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from 'grow-angular/services';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GuestGuard {
  constructor(private router: Router, private auth: AuthService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isAuthenticated = !this.auth.hasValidIdToken() || !this.auth.hasValidAccessToken();
    return isAuthenticated ? of(true) : this.router.navigate(['/']);
  }
}
