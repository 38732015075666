import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppContext } from '../contracts';
import { MenuHelper } from '../helpers';

@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  constructor(protected router: Router) {}

  goToDefaultContextPage(context: AppContext) {
    const launchers = context.launchers;
    const launcher = MenuHelper.findFirstFrameEntry(launchers);
    if (launcher) {
      this.router.navigate([`/${context.key}/frame/${launcher.id}`]);
    } else {
      this.goToProfilePage();
    }
  }

  goToProfilePage() {
    this.router.navigate(['/profile']);
  }
}
