import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class QueryGenerateService {
  constructor() {}

  createQuery(queryType: string, queryAction: string, objectType: any, dataForTable = '') {
    return `${queryType}{${queryAction} {${this.generateFieldsFromObj(objectType)} ${dataForTable}}}`;
  }

  generateFieldsFromObj(obj): string {
    const arr = [];
    Object.keys(obj).map((valueName: string) => {
      if (obj[valueName] === undefined) {
        delete obj[valueName];
      } else if (
        obj[valueName] instanceof Date ||
        obj[valueName] instanceof Blob ||
        obj[valueName] instanceof File ||
        obj[valueName] === null ||
        String(typeof obj[valueName]) !== 'object'
      ) {
        arr.push(valueName);
      } else
        obj[valueName] instanceof Array
          ? obj[valueName].length > 0
            ? arr.push(`${valueName}{${this.generateFieldsFromObj(obj[valueName][0])}}`)
            : arr.push(valueName)
          : arr.push(`${valueName}{${this.generateFieldsFromObj(obj[valueName])}}`);
    });
    return arr.join(' ');
  }
}
