<footer [attr.aria-label]="'footer' | translate" role="contentinfo" class="main-footer">
  <div class="footer-content dx-theme-border-color">
    <div class="row">
      <div class="col-lg-11 col-md-11 col-sm-10 col-10">
        <small *ngIf="!(isMobile$ | async)" id="copyright" [attr.aria-label]="'copyright' | translate">
          © {{ 'copyright' | translate: { value: year } }}
        </small>
        <ul class="dx-datagrid">
          <li>
            <small>
              <a [attr.aria-label]="'go_to' | translate: { value: 'LEGAL_INFORMATION' }" [routerLink]="'/legal'">{{
                'LEGAL_INFORMATION' | translate
              }}</a>
            </small>
          </li>
          <li>
            <small>
              <a [attr.aria-label]="'go_to' | translate: { value: 'PRIVACY' }" [routerLink]="'/privacy'"
                >{{ 'PRIVACY' | translate }}
              </a>
            </small>
          </li>
        </ul>
      </div>
      <div class="col-lg-1 col-md-1 col-sm-2 col-2">
        <div class="info-block">
        </div>
      </div>
    </div>
  </div>
</footer>
