import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-type',
  templateUrl: './type.component.html',
  styleUrls: ['./type.component.scss'],
})
export class TypeComponent implements OnInit {
  @Output() valueChange = new EventEmitter<any>();
  @Input() value: string;
  types = ['Single', 'Multi', 'Required', 'Other'];
  constructor() {}

  ngOnInit() {}

  modelChanged() {
    this.valueChange.emit(this.value);
  }
}
