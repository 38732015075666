import { Component, OnInit } from '@angular/core';
import { SpinnerService, ContextStateService, LayoutService } from '../../shared/services';
import { Observable } from 'rxjs';
import { ScreenService } from 'grow-angular/services';
import { log } from 'src/debug';

@Component({
  selector: 'app-page-content',
  templateUrl: './page-content.component.html',
  styleUrls: ['./page-content.component.scss'],
})
export class PageContentComponent implements OnInit {
  isMobile$: Observable<boolean> = this.screenService.isMobile$;
  context = this.contextState.currentContext;
  isDrawerOpen: boolean = true;
  selectedOpenMode: string = 'shrink';
  selectedPosition: string = 'left';
  selectedRevealMode: string = 'slide';
  constructor(
    private screenService: ScreenService,
    protected contextState: ContextStateService,
    public layout: LayoutService,
    public spinner: SpinnerService
  ) {}

  ngOnInit() {}

  toggleNavigation(event) {
    log(`toggleNavigation %o`, event);
    this.isDrawerOpen = event;
  }

  scrollHandler(e) {
    this.screenService.pageScroll$.next(e);
  }
}
