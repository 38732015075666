import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { log } from '../../../debug';
import { apiUrl } from '../../config';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'No-Auth': 'True',
  }),
};
const httpOptionsForFile = {
  headers: new HttpHeaders({}),
};

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private userLoaded = false;

  constructor(private http: HttpClient) {}

  public setUserLoaded(loaded: boolean) {
    this.userLoaded = loaded;
  }

  // method for api call with specific parameters
  getOneOrMutationDataItem(query: string, variables: any): Observable<any> {
    return this.http.post<any>(`${apiUrl}`, JSON.stringify({ query, variables }), httpOptions).pipe(
      map((resp) => this.handleResponse(resp)),
      catchError((err) => this.handleErrorMethod(err))
    );
  }

  getAllData(query: string): Observable<any> {
    return this.http.post<any>(`${apiUrl}`, JSON.stringify({ query }), httpOptions).pipe(
      map((resp) => this.handleResponse(resp)),
      catchError((err) => this.handleErrorMethod(err))
    );
  }

  handleResponse(resp) {
    if (resp.hasOwnProperty('errors')) {
      const message =
        resp.errors.length && resp.errors.length !== 0
          ? resp.errors.map((item) => (item.message ? item.message : item))
          : resp.errors;
      throw new Error(message instanceof Array ? message[0] : message);
    }
    return resp.data ? resp.data : resp;
  }

  handleErrorMethod(err) {
    log(err);
    return throwError(err);
  }
}
