import { Directive, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Icons, LauncherTypes } from '../../constants';
import { AppContext } from '../../contracts';
import { MenuHelper } from '../../helpers';
import { ContextStateService } from '../../services';
import { ModalService } from '../../services/modal.service';
import { LauncherModel } from '../../models';

@Directive()
export abstract class BaseMenuComponent implements OnDestroy {
  context$ = this.contextState.currentContext;
  context: AppContext;
  menuItems: LauncherModel[] = [];
  launcherTypes = LauncherTypes;
  icons = Icons;
  sub$: Array<Subscription> = [];

  constructor(
    protected router: Router,
    protected contextState: ContextStateService,
    private modalService: ModalService
  ) {}

  handleNavigation(item) {
    switch (item.type) {
      case LauncherTypes.POPUP:
        this.openModal(item);
        break;
      case LauncherTypes.GROW_IFRAME:
        this.handleAppNavigation(item);
        break;
      case LauncherTypes.IFRAME:
        this.handleAppNavigation(item);
        break;
      case LauncherTypes.FOLDER:
        this.handleFolderNavigation(item);
        break;
      case LauncherTypes.WINDOW:
        this.handleExternalNavigation(item);
        break;
      default:
        break;
    }
  }

  openModal(item: any) {
    this.modalService.open(item);
  }

  handleFolderNavigation(item) {
    const app = MenuHelper.findFirstFrameEntry(item.items);
    if (app) {
      this.handleAppNavigation(app);
    } else if (item.items?.length) {
      this.handleFolderWithoutFrameEntries(item);
    }
  }

  handleAppNavigation(item) {
    this.router.navigate([`/${this.context.key}/frame/${item.id}`]);
  }

  handleExternalNavigation(item) {
    const anchor = document.createElement('a');
    anchor.href = item.baseUrl + item.path;
    anchor.target = '_blank';
    anchor.click();
  }

  handleFolderWithoutFrameEntries(item): void {}

  checkPath(item) {
    return this.router.url === `/${this.context.key}/frame/${encodeURIComponent(item.id)}`;
  }

  getActivatedLink(links) {
    let activatedLink = null;
    links.forEach((item) => {
      if (this.checkPath(item)) {
        activatedLink = item;
      }
      if (item.items?.length && !activatedLink) {
        activatedLink = this.getActivatedLink(item.items);
      }
    });
    return activatedLink;
  }

  getParentNodes(node): any[] {
    const parents = [];
    let currentLevel = node;
    while (currentLevel.parent) {
      const parent = currentLevel.parent;
      parents.push(parent);
      currentLevel = parent;
    }
    return parents;
  }

  getTopLevelParentNode(node) {
    const parentNodes = this.getParentNodes(node);
    return parentNodes[parentNodes.length - 1];
  }

  ngOnDestroy(): void {
    this.sub$.map((subscriber) => subscriber.unsubscribe());
  }
}
