<dx-select-box
  #languageSelect
  [(value)]="value"
  valueExpr="id"
  displayExpr="name"
  [placeholder]="'select' | translate"
  [dataSource]="languages"
  [searchEnabled]="false"
  [attr.aria-label]="'switch_language' | translate"
  (valueChange)="modelChanged()"
>
</dx-select-box>
