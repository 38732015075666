import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Upsert_MemberGQL } from 'src/app/members.api.g';
import { UserViewModel } from 'src/app/shared/models';
import { ConfirmationService, ThemeService, UserService } from 'src/app/shared/services';
import { THEMES } from '../../../constants';

@Component({
  selector: 'app-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.scss'],
})
export class ThemeComponent implements OnInit {
  @Input() value: string;
  @Output() valueChange = new EventEmitter<any>();
  themes: { key: string; name: string }[];
  swithTheme: boolean = false;
  userData: UserViewModel;

  constructor(
    private translateService: TranslateService,
    private confirmationService: ConfirmationService,
    private themeService: ThemeService,
    private upsert: Upsert_MemberGQL,
    private userService: UserService
  ) {
    this.themeNameTranslate = this.themeNameTranslate.bind(this);
  }
  ngOnInit() {
    this.getUser();
    if (this.value == 'dark') {
      this.swithTheme = true;
    }

    this.themes = THEMES.map((theme) => {
      return { key: theme.name, name: theme.name };
    });
    this.themes.unshift({ key: '', name: 'default' });
  }

  getUser() {
    this.userService.getCurrentUser().subscribe((user) => {
      this.userData = user;
    });
  }

  async modelChanged() {
    if (this.swithTheme) {
      this.value = 'dark';
    } else {
      this.value = 'light';
    }
    this.themeService.setAppTheme(this.value);
    await this.upsert
      .mutate({
        input: {
          idOrKey: { uuid: this.userData.uuid },
          theme: this.value,
        },
      })
      .toPromise();
    this.confirmationService.setDataUpdated();
    this.valueChange.emit(this.value);
    window.location.reload();
  }

  themeNameTranslate(data) {
    return data ? this.translateService.instant(data.name) : '';
  }
}
