export const BUILD_INFO = {
  "MEMBERS_UI": "registry.gitlab.com/bitmedia/microservices/memberservice/memberservice-front:dev",
  "semVerMajor": 1,
  "DEFAULT_CI_REGISTRY_IMAGE": "registry.gitlab.com/bitmedia/microservices/memberservice/memberservice-front",
  "semVer": "142.17.5843-dev",
  "releaseType": "DEV",
  "TARGET_PLATFORMS": "linux/amd64,linux/arm64",
  "DOCKER_TAG": "dev",
  "grow": 1,
  "serviceName": "members-ui",
  "MEMBERS_UI_VERSION": "dev",
  "buildDate": "2024-07-17T13:13:52.1966880+00:00",
  "MEMBERS_UI_IMAGE": "registry.gitlab.com/bitmedia/microservices/memberservice/memberservice-front",
  "DOCKER_TAG_ALTERNATE": "dev"
} as const;
