import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { DxFileUploaderComponent } from 'devextreme-angular';
import { log } from '../../../../debug';
import { CAMERA, DEFAULT_PROFILE_PIC } from '../../../../magic';
import { uploadUrl } from '../../../config';
import { SpinnerService, ToastService } from '../../services';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent implements OnInit {
  @Input() username: string;
  @Input() imageUrl;
  @Output() pictureChange = new EventEmitter<{ imageUrl: string; tokens: string }>();
  @ViewChild(DxFileUploaderComponent) fileUploader: any;
  defaultUrl = CAMERA;
  defaultProfilePicture = DEFAULT_PROFILE_PIC;
  showDeleteButton: boolean = true;
  uploadApiUrl: string = '';
  httpHeaders: { [key: string]: string } = {};
  @Output() uploadString = new EventEmitter<any>();

  constructor(private toast: ToastService, public spinner: SpinnerService, private oauthService: OAuthService) {
    this.uploadApiUrl = uploadUrl;
    const access_token = this.oauthService.getAccessToken();
    if (access_token) {
      log(`Adding Authorization Header`);
      this.httpHeaders['Authorization'] = `Bearer ${access_token}`;
    }
  }
  ngOnInit() {
    this.imageUrl = this.imageUrl || this.defaultUrl;
    if (this.imageUrl == this.defaultProfilePicture) {
      this.showDeleteButton = false;
    }
  }

  selectPicture(event) {
    if (event.value[0]) {
      let reader = new FileReader();
      reader.readAsDataURL(event.value[0]);
      reader.onloadend = () => {
        this.imageUrl = reader.result.toString();
      };
    }
  }

  uploadPicture() {
    this.fileUploader.instance._isCustomClickEvent = true;
    this.doUpload();
  }

  deleteImage() {
    this.imageUrl = this.defaultUrl;
    this.pictureChange.emit({ imageUrl: this.defaultUrl, tokens: '' });
  }

  uploadCancel() {
    this.fileUploader.instance._$content[0].querySelectorAll('.dx-fileuploader-cancel-button')[0].click();
  }
  doUpload() {
    this.fileUploader.instance._$content[0].querySelectorAll('.dx-fileuploader-button')[0].click();
  }

  uploaded(event) {
    if (event.file && event.request?.status === 200) {
      this.spinner.spinnerLoading.next(false);
      this.fileUploader.instance.reset();
      this.pictureChange.emit({ imageUrl: this.imageUrl, tokens: JSON.parse(event.request?.response) });
      this.uploadString.emit(JSON.parse(event.request?.response));
    }
  }
}
