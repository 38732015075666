import { Component, EventEmitter, Output, Input } from '@angular/core';
import { ConfigService } from 'src/app/shared/services/config.service';
import { ConfirmationService } from 'src/app/shared/services';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
})
export class LanguageComponent {
  @Output() valueChange = new EventEmitter<any>();
  @Input() value: string = this.config.getDefaultLocale();
  languages = [
    { id: 'en', name: 'English' },
    { id: 'de', name: 'Deutsch' },
  ];

  constructor(private config: ConfigService, private confirmationService: ConfirmationService) {}

  modelChanged() {
    this.confirmationService.setDataUpdated();
    this.valueChange.emit(this.value);
  }
}
