import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SpinnerService } from '../../services';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit, OnDestroy {
  @Input() loadingVisible: boolean;
  unsubscribe: Array<Subscription> = [];

  constructor(public spinnerService: SpinnerService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsubscribe.map((item) => item.unsubscribe());
  }
}
