import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { DxTreeViewComponent } from 'devextreme-angular';
import { fromEvent, Observable } from 'rxjs';
import { ScreenService } from 'grow-angular/services';
import { cloneDeep } from 'lodash';
import { BaseMenuComponent } from 'src/app/shared/components/menu/base-menu.component';
import { ContextStateService } from 'src/app/shared/services';
import { ModalService } from 'src/app/shared/services/modal.service';

@Component({
  selector: 'app-main-navigation',
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.scss'],
})
export class MainNavigationComponent extends BaseMenuComponent implements OnInit, OnDestroy, AfterViewInit {
  isMobile$: Observable<boolean> = this.screenService.isMobile$;
  toolbarContent = [
    {
      location: 'before',
      cssClass: 'menu-button dx-theme-accent-as-text-color',
      template: 'burgerMenu',
    },
  ];
  isDrawerOpen = true;
  @ViewChild(DxTreeViewComponent, { static: false }) treeView: DxTreeViewComponent;
  @Output() drawerOpen = new EventEmitter();
  currentItem: any;
  previousItem: any;
  SUBMENUBACKGROUND = 'dx-theme-border-color-as-background-color';
  nodeClass = 'dx-treeview-node';
  selectedClass = 'dx-state-selected';
  openedSubMenuClass = 'dx-treeview-node-container-opened';

  constructor(
    protected router: Router,
    protected contextState: ContextStateService,
    protected route: ActivatedRoute,
    protected screenService: ScreenService,
    modalService: ModalService
  ) {
    super(router, contextState, modalService);
  }

  ngOnInit(): void {
    this.sub$.push(
      this.context$.subscribe((data) => {
        this.context = data;
        this.menuItems = cloneDeep(data.menuItems.primary);
      }),
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.updateMenuState();
        }
      })
    );
  }

  menuToast() {
    this.isDrawerOpen = !this.isDrawerOpen;
    this.drawerOpen.next(this.isDrawerOpen);
  }

  ngAfterViewInit() {
    this.sub$.push(
      this.route.url.subscribe((queryParams) => {
        this.updateMenuState();
      })
    );
  }

  deactivateCurrent(newActiveItem) {
    const tree = this.treeView;
    const current = this.currentItem;
    current.selected = false;
    this.previousItem = this.currentItem;
    this.currentItem = null;
    const parentNode = this.getTopLevelParentNode(current);
    if (parentNode && (!newActiveItem || newActiveItem.parent !== current.parent)) {
      this.collapseItem(parentNode);
    } else if (!parentNode) {
      this.collapseChildren(this.menuItems);
    }
    this.treeView.instance.unselectItem(current.id);
  }

  activateNew(item) {
    if (!item) {
      return;
    }
    item.selected = true;
    if (item.parent) {
      this.expandParents(item);
    } else if (item.items?.length) {
      this.expandItem(item);
    }
    this.treeView?.instance?.selectItem(item.id);
    this.currentItem = item;
  }

  updateMenuState() {
    const currentItem = this.currentItem;
    const newItem = this.getActivatedLink(this.menuItems);
    this.updateTreeView(currentItem, newItem);
  }

  updateTreeView(currentItem, newActiveItem) {
    if (!this.treeView || currentItem === newActiveItem) {
      return;
    }
    let isPartOfCurrentItem = false;
    if (currentItem && currentItem.items) {
      isPartOfCurrentItem = !!currentItem.items.find((data) => data.id === newActiveItem.id);
    }
    if (currentItem && !isPartOfCurrentItem) {
      this.deactivateCurrent(newActiveItem);
      this.updatePreviouslySelectedNodeStyle();
      this.updatePreviouslySelectedParentNodeStyle();
    }

    if (newActiveItem) {
      this.activateNew(newActiveItem);

      this.updateSelectedNodeParentStyle();
      this.updateOpenedSubMenuStyle();
    }
  }

  collapseChildren(nodes) {
    nodes.forEach((node) => {
      if (node.items?.length) {
        this.collapseChildren(node.items);
        this.collapseItem(node);
      }
    });
  }

  collapseItem(node) {
    this.treeView.instance.collapseItem(node.id);
  }

  expandParents(node) {
    const parents = this.getParentNodes(node);
    parents.forEach((parent) => {
      this.expandItem(parent);
    });
  }

  expandItem(node) {
    this.treeView.instance.expandItem(node.id);
  }

  updateSelectedNodeParentStyle() {
    const treeElement = this.treeView.instance.element();
    const parentNodes = this.getParentNodes(this.currentItem);
    parentNodes.forEach((parent) => {
      const parentElement = treeElement.querySelector(`.${this.nodeClass}[data-item-id='${parent.id}']`);
      parentElement.classList.add(this.SUBMENUBACKGROUND);
    });
  }

  updateOpenedSubMenuStyle() {
    if (!this.treeView) {
      return;
    }
    const treeElement = this.treeView.instance.element();
    const openedSubMenus = treeElement.querySelectorAll(`ul .${this.openedSubMenuClass}`);
    openedSubMenus.forEach((subMenu) => {
      if (!subMenu?.classList.contains(this.SUBMENUBACKGROUND)) {
        subMenu?.classList.add(this.SUBMENUBACKGROUND);
      }
    });
  }

  updatePreviouslySelectedNodeStyle() {
    const treeElement = this.treeView.instance.element();
    const element = treeElement.querySelector(`.${this.nodeClass}[data-item-id='${this.previousItem.id}']`);
    if (element) {
      element.classList.remove(this.selectedClass);
    }
  }

  updatePreviouslySelectedParentNodeStyle() {
    const treeElement = this.treeView.instance.element();
    const parentNodes = this.getParentNodes(this.previousItem);
    parentNodes.forEach((parent) => {
      const parentElement = treeElement.querySelector(`.${this.nodeClass}[data-item-id='${parent.id}']`);
      parentElement.classList.remove(this.SUBMENUBACKGROUND);
    });
  }

  menuItemClick(e) {
    e.event.stopPropagation();
    this.handleNavigation(e.itemData);
  }

  handleFolderWithoutFrameEntries(item) {
    if (item.expanded) {
      this.collapseItem(item);
    } else {
      this.expandItem(item);
    }
  }
}
