import { ContactDataInterface } from '../contracts';

export class UserModel {
  constructor(
    public id?: string,
    public name?: string,
    public firstName?: string,
    public lastName?: string,
    public contactEmail?: string,
    public locale?: string,
    public dateFormat?: string,
    public theme?: string,
    public imageBase64?: string,
    public uuid?: string,
    public roles?: string,
    public phone?: string,
    public contact?: ContactDataInterface
  ) {}
}
