import { BUILD_INFO } from './grow_version.g';

const CONSOLE_ID = ['%c[%s %s]', 'color:rgb(172 17 47)', BUILD_INFO.serviceName, BUILD_INFO.semVer];

export function log(msg: any, ...optional: any[]) {
  console.log(...CONSOLE_ID, msg, ...optional);
}
export function info(msg: any, ...optional: any[]) {
  console.info(...CONSOLE_ID, msg, ...optional);
}
export function error(msg: any, ...optional: any[]) {
  console.error(...CONSOLE_ID, msg, ...optional);
}
export function warn(msg: any, ...optional: any[]) {
  console.warn(...CONSOLE_ID, msg, ...optional);
}
export function dir(item: any) {
  console.log(...CONSOLE_ID);
  console.dir(item);
}
