<dx-drawer
  [ngClass]="{ 'primary-menu-hidden': !(context | async).menuItems.primary.length || (isMobile$ | async), 'mobile-screen': isMobile$ | async }"
  [openedStateMode]="selectedOpenMode"
  [position]="selectedPosition"
  [revealMode]="selectedRevealMode"
  [(opened)]="isDrawerOpen"
  [height]="400"
  template="template"
  [closeOnOutsideClick]="false"
>
  <app-main-navigation
    (drawerOpen)="toggleNavigation($event)"
    *dxTemplate="let data of 'template'"
  ></app-main-navigation>
  <dx-scroll-view role="main" class="full-height-scrollable" (onScroll)="scrollHandler($event)">
    <app-header></app-header>
    <div class="content" id="main-content" [ngClass]="layout.contentClass">
      <ng-content></ng-content>
    </div>
    <!--<app-footer *ngIf="layout.showFooter"></app-footer>-->
  </dx-scroll-view>
</dx-drawer>
<app-mobile-navigation class="mobile-navigation" *ngIf="isMobile$ | async"></app-mobile-navigation>
<app-spinner></app-spinner>
