<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: '#spinnerContent' }"
  [visible]="spinnerService.spinnerLoading | async"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [closeOnOutsideClick]="false"
  height="300px"
>
</dx-load-panel>
