import { NgModule } from '@angular/core';
import {
  DxActionSheetModule,
  DxButtonModule,
  DxDataGridModule,
  DxDraggableModule,
  DxDrawerModule,
  DxDropDownButtonModule,
  DxFileUploaderModule,
  DxFormModule,
  DxLoadPanelModule,
  DxMenuModule,
  DxPopupModule,
  DxRadioGroupModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxSwitchModule,
  DxTabsModule,
  DxTagBoxModule,
  DxTextBoxModule,
  DxToolbarModule,
  DxTreeViewModule,
  DxValidationGroupModule,
  DxValidationSummaryModule,
  DxValidatorModule,
  DxCheckBoxModule,
  DxTooltipModule,
} from 'devextreme-angular';
import {
  HeaderComponent,
  PageContentComponent,
  MainNavigationComponent,
  MobileNavigationComponent,
  SecondaryMenuComponent,
} from '../layouts';
import { CommonModule } from '@angular/common';
import { FooterComponent } from '../layouts/footer/footer.component';
import { RoleSelectComponent } from './components/selects/role/role-select.component';
import { SpinnerComponent } from './components/spiner/spinner.component';
import { LanguageComponent } from './components/selects/language/language.component';
import { CountryComponent } from './components/selects/country/country.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { ThemeComponent } from './components/selects/theme/theme.component';
import { HintForSelectsDirective, IsConfirmDirective } from './directive';
import { NoDataComponent } from './components/no-data/no-data.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { TypeComponent } from './components/selects/type/type.component';
import { FeatherModule } from 'angular-feather';
import {
  Users,
  User,
  PlayCircle,
  Info,
  ChevronDown,
  ChevronUp,
  ChevronLeft,
  ChevronRight,
  MoreHorizontal,
  Save,
  Plus,
  Filter,
  Search,
  RefreshCw,
  Upload,
  Download,
  List,
  Grid,
  Heart,
  Link2,
  Eye,
  BarChart2,
  Edit2,
  Check,
  CheckSquare,
  Trash2,
  Minus,
  RotateCw,
  CloudOff,
  Menu,
  Home,
  Film,
  Box,
  ShoppingBag,
  Columns,
  ExternalLink,
  HelpCircle,
} from 'angular-feather/icons';
import { CustomGrid, Hierarchy, UserLock } from './constants';
import { LoginFormComponent } from '../pages/login-form/login-form.component';

const icons = {
  Users,
  User,
  PlayCircle,
  Info,
  ChevronDown,
  ChevronUp,
  ChevronLeft,
  ChevronRight,
  Edit2,
  Check,
  CheckSquare,
  Save,
  MoreHorizontal,
  Filter,
  Search,
  RefreshCw,
  Upload,
  Download,
  List,
  Grid,
  Heart,
  Link2,
  Eye,
  BarChart2,
  Trash2,
  Plus,
  Minus,
  RotateCw,
  CloudOff,
  Menu,
  Hierarchy,
  UserLock,
  CustomGrid,
  Home,
  Film,
  Box,
  ShoppingBag,
  Columns,
  ExternalLink,
  HelpCircle,
};

@NgModule({
  declarations: [
    HeaderComponent,
    HeaderComponent,
    PageContentComponent,
    MainNavigationComponent,
    LoginFormComponent,
    FooterComponent,
    MobileNavigationComponent,
    SecondaryMenuComponent,
    RoleSelectComponent,
    SpinnerComponent,
    LanguageComponent,
    CountryComponent,
    ThemeComponent,
    NoDataComponent,
    IsConfirmDirective,
    FileUploaderComponent,
    HintForSelectsDirective,
    TypeComponent,
  ],
  imports: [
    DxDraggableModule,
    DxTagBoxModule,
    DxDataGridModule,
    DxDrawerModule,
    DxButtonModule,
    DxFormModule,
    DxRadioGroupModule,
    DxToolbarModule,
    DxScrollViewModule,
    DxSwitchModule,
    DxTreeViewModule,
    DxActionSheetModule,
    DxDropDownButtonModule,
    DxMenuModule,
    CommonModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxLoadPanelModule,
    DxValidatorModule,
    DxValidationGroupModule,
    DxValidationSummaryModule,
    TranslateModule,
    RouterModule,
    DxTextBoxModule,
    DxPopupModule,
    DxFileUploaderModule,
    DxTabsModule,
    DxCheckBoxModule,
    FeatherModule.pick(icons),
    DxTooltipModule,
  ],
  exports: [
    HeaderComponent,
    DxDataGridModule,
    PageContentComponent,
    HeaderComponent,
    LoginFormComponent,
    DxButtonModule,
    MainNavigationComponent,
    DxDrawerModule,
    DxTagBoxModule,
    DxRadioGroupModule,
    DxTreeViewModule,
    DxToolbarModule,
    DxMenuModule,
    DxTextBoxModule,
    FooterComponent,
    DxSwitchModule,
    MobileNavigationComponent,
    SecondaryMenuComponent,
    CommonModule,
    DxFormModule,
    DxDropDownButtonModule,
    DxActionSheetModule,
    DxTextBoxModule,
    DxSelectBoxModule,
    RoleSelectComponent,
    DxLoadPanelModule,
    DxValidatorModule,
    DxValidationGroupModule,
    DxValidationSummaryModule,
    DxScrollViewModule,
    SpinnerComponent,
    LanguageComponent,
    CountryComponent,
    DxDraggableModule,
    DxPopupModule,
    ThemeComponent,
    DxTabsModule,
    DxFileUploaderModule,
    NoDataComponent,
    IsConfirmDirective,
    FileUploaderComponent,
    DxCheckBoxModule,
    HintForSelectsDirective,
    TypeComponent,
    FeatherModule,
    DxTooltipModule,
  ],
})
export class SharedModule {}
