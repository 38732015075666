<dx-select-box
  #countrySelect
  [value]="value"
  [placeholder]="'select' | translate"
  [dataSource]="countries"
  [searchEnabled]="false"
  [disabled]="disabled"
  (onValueChanged)="changedValue($event)"
  (onSelectionChanged)="modelChanged($event)"
>
  <dx-validator>
    <dxi-validation-rule *ngIf="validate" type="required" [message]="'language' | translate"></dxi-validation-rule>
  </dx-validator>
</dx-select-box>
