import { OwnProfileAccessEnum } from 'src/app/members.api.g';
import { UserModel } from './user.model';

export class UserViewModel extends UserModel {
  constructor(
    public ownProfileAccess?: OwnProfileAccessEnum,
    public id?: string,
    public name?: string,
    public firstName?: string,
    public lastName?: string,
    public contactEmail?: string,
    public locale?: string,
    public dateFormat?: string,
    public theme?: string,
    public imageBase64?: string,
    public roles?: string,
    public uuid?: string,
    public location?: string,
    public phone?: string
  ) {
    super(id, name, firstName, lastName, contactEmail, locale, dateFormat, theme, imageBase64, roles, uuid, phone);
  }
}
