import { LauncherTypes } from '../constants';
import { LauncherModel } from '../models';
import Guid from 'devextreme/core/guid';

//TODO: refactor mapping and launcher model
export class MenuHelper {
  public static createMenu(launchers): LauncherModel[] {
    return launchers.reduce((accumulator, currentValue) => {
      return MenuHelper.addMenuItem(currentValue, launchers, accumulator);
    }, []);
  }

  //TODO: refactor
  public static addMenuItem(
    launcher: LauncherModel,
    source: LauncherModel[],
    accumulator: LauncherModel[]
  ): LauncherModel[] {
    const label = launcher.label;
    const keys = label.split('\\');
    const text = keys[keys.length - 1];
    const currentLauncher = {
      ...launcher,
      text: text,
      level: keys.length,
      previewText: MenuHelper.createPreviewText(text)
    };
    if (keys.length > 1) {
      keys.pop();
      const parentLabel = keys.join('\\');
      let parentLauncher = MenuHelper.findRecursivelyByPath(keys, accumulator);
      if (!parentLauncher) {
        parentLauncher = source.find((l) => l.label === parentLabel);
        if (parentLauncher) {
          parentLauncher = { ...parentLauncher };
        } else {
          parentLauncher = MenuHelper.createLauncherFolder(parentLabel);
        }
      }
      if (!parentLauncher.items) {
        parentLauncher.items = [];
      }
      if (!parentLauncher.items.find((l) => l.label === currentLauncher.label)) {
        parentLauncher.items.push(currentLauncher);
        currentLauncher.parent = parentLauncher;
      }
      MenuHelper.addMenuItem(parentLauncher, source, accumulator);
    } else {
      if (!accumulator.find((l) => l.label === currentLauncher.label)) {
        accumulator.push(currentLauncher);
      }
    }
    return accumulator;
  }

  public static findRecursivelyByPath(parts: string[], items: LauncherModel[], depth: number = 1): LauncherModel {
    let reachedMaxDepth = false;
    if (depth === parts.length) {
      reachedMaxDepth = true;
    }
    const path = parts.slice(0, depth).join('/');
    let existingItem = items.find((item) => {
      return item.label === path;
    });
    if (!reachedMaxDepth && existingItem.items?.length) {
      existingItem = MenuHelper.findRecursivelyByPath(parts, existingItem.items, ++depth);
    }
    return existingItem;
  }

  public static findRecursivelyById(items: LauncherModel[], id: string): LauncherModel {
    return MenuHelper.findRecursivelyByProperty(items, 'id', id);
  }

  public static findRecursivelyByProperty(items: LauncherModel[], property: string, value: any): LauncherModel {
    return MenuHelper.findByPropertyInArrayDF(items, property, [value]);
  }

  public static findByPropertyInArrayDF(items: LauncherModel[], property: string, values: any[]): LauncherModel {
    let existingItem = null;
    for (let i = 0; i < items.length; i++) {
      if (values.includes(items[i][property])) {
        existingItem = items[i];
      } else {
        if (items[i].items?.length) {
          existingItem = MenuHelper.findByPropertyInArrayDF(items[i].items, property, values);
        }
      }
      if (existingItem) {
        break;
      }
    }
    return existingItem;
  }

  public static findByPropertyInArrayBF(items: LauncherModel[], property: string, values: any[]) {
    const queue = [];
    for (let i = 0; i < items.length; i++) {
      queue.unshift(items[i]);
    }
    let existingItem = null;
    while (queue.length) {
      const item = queue.pop();
      if (values.includes(item[property])) {
        existingItem = item;
        break;
      } else {
        if (item.items?.length) {
          for (let i = 0; i < item.items.length; i++) {
            queue.unshift(item.items[i]);
          }
        }
      }
    }
    return existingItem;
  }

  public static findFirstFrameEntry(items: LauncherModel[], strategy: 'bf' | 'df' = 'bf'): LauncherModel {
    return MenuHelper[`findByPropertyInArray${strategy.toUpperCase()}`](items, 'type', [
      LauncherTypes.GROW_IFRAME,
      LauncherTypes.IFRAME
    ]);
  }

  public static sortLaunchers(items: LauncherModel[]): void {
    items.sort(MenuHelper.compareLaunchers);
    items.forEach((item) => {
      if (item.items && item.items.length) {
        MenuHelper.sortLaunchers(item.items);
      }
    });
  }

  private static createPreviewText(text: string): string {
    if (!text) {
      return '';
    }
    const words = text.split(' ');
    let previewText = '';
    if (words.length < 2) {
      previewText = words[0].slice(0, 2);
    } else {
      previewText = words
        .slice(0, 2)
        .map((word) => word[0])
        .join('');
    }
    return previewText;
  }

  private static createLauncherFolder(label): LauncherModel {
    return { id: new Guid().toString(), uuid: '', key: '', type: LauncherTypes.FOLDER, label };
  }

  private static compareLaunchers(a: LauncherModel, b: LauncherModel): number {
    if (a.order && b.order) {
      return a.order - b.order;
    }

    if (a.order && !b.order) {
      return -1;
    } else if (!a.order && b.order) {
      return 1;
    } else {
      return a.label.localeCompare(b.label);
    }
  }
}
