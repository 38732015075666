import { Component, HostListener, OnInit } from '@angular/core';
import { UserService } from './shared/services';
import { ThemeService } from './shared/services/theme.service';
import { Title } from '@angular/platform-browser';
import { AuthService, ScreenService } from 'grow-angular/services';
import { log } from 'src/debug';
import { GrowTokenService } from './shared/services/grow-token-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private auth: AuthService,
    private themeService: ThemeService,
    private screenService: ScreenService,
    private userService: UserService,
    private growTokenService: GrowTokenService,
    private title: Title
  ) {}

  ngOnInit() {
    this.addResizeHandler();

    this.configureTheme();
    this.title.setTitle(window.__CONFIG.TITLE || 'Memberservice');

    this.loadUser();

    this.addStorageHandler();
  }

  private configureTheme() {
    this.themeService.setAppTheme(localStorage.getItem('default_theme'));
  }

  private addResizeHandler() {
    this.screenService._resize$.subscribe();
  }

  private addStorageHandler() {
    this.auth.addStorageStateHandler();
  }

  loadUser() {
    this.userService.getCurrentUser().subscribe();
  }
}
