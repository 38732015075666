import { Injectable } from '@angular/core';
import { AuthConfig } from 'angular-oauth2-oidc';
import { log } from '../../../debug';
import { Languages, THEMES } from '../constants';
import { AuthServiceConfig } from 'grow-angular/services';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  envJSON = window.__CONFIG;

  constructor() {
    if (!localStorage.getItem('default_theme')) {
      let currentTheme = THEMES.find((theme) => theme.name === this.envJSON.DEFAULT_THEME)?.baseTheme;
      localStorage.setItem('default_theme', currentTheme || THEMES[0].baseTheme);
    }
  }

  getAuthConfig(): AuthConfig {
    const envJSON = this.envJSON;
    const issuer = envJSON.IDM_ISSUER;
    const clientId = envJSON.IDM_CLIENTID;
    let scope = envJSON.IDM_SCOPE ?? '';

    const retVal: AuthServiceConfig = {
      issuer,
      //redirectUri: window.location.href.split('?')[0],
      //postLogoutRedirectUri: window.location.origin,
      clientId,
      scope,
      //requireHttps: true,
      //responseType: 'code',
      //dummyClientSecret,
      //useSilentRefresh: false,
    };
    return retVal;
  }

  getDefaultLocale(): string {
    return this.envJSON.APP_LOCALE || Languages.EN;
  }

  getAppLocales(): string[] {
    return this.envJSON.ALLOWED_LOCALES ? this.envJSON.ALLOWED_LOCALES.split(',') : [];
  }
}
